import { SVGProps } from 'react';
import styled from 'styled-components';

const SVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.428 8.043a5.51 5.51 0 0 0 2.552 2.54.518.518 0 0 0 .511-.04l1.636-1.09a.523.523 0 0 1 .496-.045l3.06 1.311a.52.52 0 0 1 .313.542A3.136 3.136 0 0 1 10.886 14 8.885 8.885 0 0 1 2 5.115a3.137 3.137 0 0 1 2.739-3.111.521.521 0 0 1 .542.313L6.594 5.38a.523.523 0 0 1-.044.492l-1.086 1.66a.518.518 0 0 0-.036.51v0Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const PhoneIcon = styled(SVG)`
  font-size: ${props => (props.fontSize ? props.fontSize : '1.6rem')};
  color: ${({ color, theme }) =>
    color ? color : theme.colors.neutralColors.charcoal};
  vertical-align: middle;
  shape-rendering: inherit;
  transform: translate3d(0, 0, 0);
`;
export default PhoneIcon;
