import {
  brandColors,
  neutralColors,
  textColors,
  systemSupportColors,
} from "./commonsColors";
import breakPoints from "./breakPoints";

const commonsTheme = {
  colors: { brandColors, neutralColors, textColors, systemSupportColors },
  breakPoints,
};

export default commonsTheme;
