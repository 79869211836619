const config = {
  aws: {
    region: 'us-east-1',
  },
  auth: {
    userPoolId: process.env.REACT_APP_USER_POOL_ID || 'us-east-1_omWjrYrSm',
    userPoolWebClientId:
      process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID ||
      '2f3qv1e60e2v0vn814qgtqnnok',
    scope: [
      'email',
      'phone_number',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],
    userPoolDomain:
      process.env.REACT_APP_USER_POOL_DOMAIN || 'auth.dev.commonsclinic.com',
    cookieDomain:
      process.env.REACT_APP_COOKIE_DOMAIN || 'dev.commonsclinic.com',
    cookieSecure: true,
  },
  websiteBaseUrl:
    process.env.REACT_APP_WEBSITE_BASE_URL || 'app.dev.commonsclinic.com',
};

export default config;
