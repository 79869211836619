import { SVGProps } from 'react';
import styled from 'styled-components';

const SVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8 8v3M8 5.5V7" stroke="currentColor" strokeWidth={1.5} />
    <circle cx={8} cy={8} r={5.25} stroke="currentColor" strokeWidth={1.5} />
  </svg>
);

const InfoIcon = styled(SVG)`
  font-size: ${props => (props.fontSize ? props.fontSize : '1.6rem')};
  color: ${({ color, theme }) =>
    color ? color : theme.colors.neutralColors.charcoal};
  vertical-align: middle;
  shape-rendering: inherit;
  transform: translate3d(0, 0, 0);
`;
export default InfoIcon;
