import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { CognitoUser } from 'amazon-cognito-identity-js';
import config from '../../config';
import Help from '../Help';
import Header from '../../components/Header';
import Emergency from '../../components/Emergency/Index';
import OTPInput from '../../components/OTPInput/OTPInput';
import { InlineButton, Button } from '../../components/Buttons';
import Input from '../../components/Input';
import {
  StyledButtonContainer,
  StyledForgottenButtonContainer,
  StyledForm,
  StyledGreeting,
  StyledHelpButton,
  StyledOTPContainer,
  StyledParagraph,
} from './styles';

export type OTPCodeType = [string, string, string, string, string];
export const initialValue: OTPCodeType = ['', '', '', '', ''];

const Login = () => {
  const [username, setUsername] = useState<string>('');
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
  const [codeWindowVisible, setCodeWindowVisible] = useState<boolean>(false);
  const [otpValue, setOTPValue] = useState<OTPCodeType>(initialValue);
  const [otpDisabled, setOTPDisabled] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [showHelpScreen, setShowHelpScreen] = useState(false);
  const [invalidState, setInvalidState] = useState(false);

  // Handle submit when all values are filled
  useEffect(() => {
    if (otpValue.every(v => v !== '')) {
      setOTPDisabled(true);
      handleCodeSubmit(otpValue.join(''));
    }
  }, [otpValue]);

  const handleSignin = async (e?: BaseSyntheticEvent) => {
    e?.preventDefault();
    let formattedUsername = username.toLowerCase();

    if (username.length === 10 && username.match(/^[0-9]+$/)) {
      // hard coding country code for now
      formattedUsername = `+1${username}`;
    }
    setCodeWindowVisible(true);
    try {
      const response = await Auth.signIn(formattedUsername);
      setCognitoUser(response);
      // eslint-disable-next-line no-empty
    } catch {}
  };

  const handleCodeSubmit = async (submittedCode: string) => {
    try {
      const authResponse = await Auth.sendCustomChallengeAnswer(
        cognitoUser,
        submittedCode,
      );
      if (authResponse.signInUserSession) {
        window.location.href = `https://${config.websiteBaseUrl}`;
      } else {
        setErrorText('This code is not valid. Please try again.');
      }
    } catch {
      setErrorText(
        'Incorrect submission, please enter your email or mobile number again',
      );
      setInvalidState(true);
      return;
    }
  };

  const handleReset = () => {
    if (errorText) {
      resetOTP();
    }
    if (invalidState) {
      resetState();
    }
  };

  const handleResendCode = () => {
    resetOTP();
    handleSignin();
  };

  const resetOTP = () => {
    setOTPValue(initialValue);
    setOTPDisabled(false);
    setErrorText('');
  };

  const resetState = () => {
    setCodeWindowVisible(false);
    setUsername('');
    setOTPValue(initialValue);
    setOTPDisabled(false);
    setErrorText('');
    setInvalidState(false);
  };

  const isNextDisabled = () => {
    return (
      /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(username) ||
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        username,
      )
    );
  };

  if (showHelpScreen) return <Help closeHelp={setShowHelpScreen} />;

  if (codeWindowVisible)
    return (
      <>
        <Header />
        <StyledForm>
          <div>
            <StyledGreeting>Confirm it&apos;s you</StyledGreeting>
            <StyledParagraph>
              We have sent a code to <br /> {username}
            </StyledParagraph>
            <StyledOTPContainer onClick={handleReset}>
              <OTPInput
                value={otpValue}
                setValue={setOTPValue}
                disabled={otpDisabled}
                errorText={errorText}
                setErrorText={setErrorText}
                handleReset={handleReset}
              />

              <InlineButton type="button" onClick={resetState}>
                Back to log in
              </InlineButton>
            </StyledOTPContainer>
          </div>
          {/* DIV prevents a react bug where the button is autofocused on mobile */}
          <div />
          <StyledButtonContainer>
            <Button
              type="button"
              onClick={handleResendCode}
              nonMobileWidth="100%"
              variant="ghost"
              disabled={invalidState}
            >
              Resend code
            </Button>
            {invalidState && (
              <StyledHelpButton
                type="button"
                nonMobileWidth="100%"
                onClick={() => {
                  resetState();
                  setShowHelpScreen(true);
                }}
              >
                Need help?
              </StyledHelpButton>
            )}
          </StyledButtonContainer>
        </StyledForm>
      </>
    );

  return (
    <>
      <Header></Header>
      <StyledForm onSubmit={handleSignin}>
        <div>
          <StyledGreeting>Welcome to Commons</StyledGreeting>
          <StyledParagraph>
            Log in with your email address or mobile phone number
          </StyledParagraph>
          <Input
            label="Email or mobile phone number"
            value={username}
            onChange={e => setUsername(e.target.value)}
            required
          />
          <StyledForgottenButtonContainer>
            <InlineButton type="button" onClick={() => setShowHelpScreen(true)}>
              Forgotten your login credentials?
            </InlineButton>
          </StyledForgottenButtonContainer>
          <Emergency />
        </div>
        <Button
          type="submit"
          nonMobileWidth="100%"
          disabled={!isNextDisabled()}
        >
          Next
        </Button>
      </StyledForm>
    </>
  );
};

export default Login;
