import { DefaultTheme } from 'styled-components';
import { ButtonColorBaseType, ButtonVarianceType } from './types';

const getCharcoalStyles = (
  theme: DefaultTheme,
  variant: ButtonVarianceType,
  disabled?: boolean,
) => {
  const { colors } = theme;
  switch (variant) {
    case 'solid':
      if (disabled) {
        // Charcoal / Solid / Disabled
        return `
          background: ${colors.neutralColors.concrete};
          border: 1px solid ${colors.neutralColors.concrete};
          color: ${colors.textColors.tertiaryDark};
          pointer-events: none;
        `;
      }
      // Charcoal / Solid
      return `
        background: ${colors.neutralColors.charcoal};
        border: 1px solid ${colors.neutralColors.charcoal};
        color: ${colors.textColors.primaryLight};
        &:hover, &:focus {
          background: ${colors.brandColors.coralLight};
          border: 1px solid ${colors.brandColors.coralLight};
          color: ${colors.textColors.primaryDark};
        }
      `;
    case 'ghost':
      if (disabled) {
        //Charcoal / Ghost / Disabled
        return `        
      background: transparent;
      border: 1px solid ${colors.neutralColors.lightestGray};
      color: ${colors.textColors.tertiaryDark};
      pointer-events: none;
      `;
      }
      //Charcoal / Ghost
      return `        
    background: transparent;
    border: 1px solid ${colors.textColors.primaryDark};
    color: ${colors.textColors.primaryDark};
    &:hover, &:focus  {
      border: 1px solid ${colors.brandColors.coralLight};
      box-shadow: 0 0 0 1px ${colors.brandColors.coralLight};
    }
    `;
  }
};
const getWhiteStyles = (
  theme: DefaultTheme,
  variant: ButtonVarianceType,
  disabled?: boolean,
) => {
  const { colors } = theme;
  switch (variant) {
    case 'solid':
      if (disabled) {
        // White / Solid / Disabled
        return `
        background: ${colors.neutralColors.jetBlack};
        border: 1px solid ${colors.neutralColors.jetBlack};
        color: ${colors.textColors.tertiaryLight};
        pointer-events: none;
      `;
      }
      // White / Solid
      return `
      background: ${colors.neutralColors.white};
      border: 1px solid ${colors.neutralColors.white};
      color: ${colors.textColors.primaryDark};
      &:hover, &:focus  {
        background: ${colors.brandColors.coralLight};
        border: 1px solid ${colors.brandColors.coralLight};
        color: ${colors.textColors.primaryDark};
      }
    `;
    case 'ghost':
      if (disabled) {
        //White / Ghost / Disabled
        return `        
      background: transparent;
      border: 1px solid ${colors.neutralColors.darkerGray};
      color: ${colors.textColors.tertiaryLight};
      pointer-events: none;
      `;
      }
      //White / Ghost
      return `        
    background: transparent;
    border: 1px solid ${colors.textColors.primaryLight};
    color: ${colors.textColors.primaryLight};
    &:hover, &:focus  {
      border: 1px solid ${colors.brandColors.coralLight};
      box-shadow: 0 0 0 1px ${colors.brandColors.coralLight};  
    }
    `;
  }
};

export const getStyles = (
  theme: DefaultTheme,
  colorBase: ButtonColorBaseType,
  variant: ButtonVarianceType,
  disabled?: boolean,
  nonMobileWidth?: string,
) => {
  // TODO: Investigate IE solution for fit-content
  const getButtonStyles = () => {
    if (colorBase === 'charcoal') {
      return getCharcoalStyles(theme, variant, disabled);
    }
    if (colorBase === 'white') {
      return getWhiteStyles(theme, variant, disabled);
    }
  };
  return `
  ${getButtonStyles()}
  font-family: "Moderat";
  cursor: pointer;
  display: block;
  font-weight: 700;
  text-align: center;
  padding: 9px 33px;
  border-radius: 4px;
  width: 100%;
  font-size: 1.6rem;
  text-decoration: none;
  transition: all .1s;
  outline: none;
  ${theme.breakPoints.tablet} {
    width: ${nonMobileWidth ? nonMobileWidth : 'fit-content'};
  }
  ${theme.breakPoints.desktop} {
  }
`;
};
