import styled from 'styled-components';
import { InfoIcon } from '../../assets/SVG';
import { InputPropsType } from '.';

export const StyledContainer = styled.div<
  Pick<InputPropsType, 'nonMobileWidth' | 'mobileWidth'>
>`
  width: ${({ mobileWidth }) => (mobileWidth ? mobileWidth : '100%')};
  position: relative;
  ${({ theme }) => theme.breakPoints.tablet} {
    width: ${({ nonMobileWidth }) =>
      nonMobileWidth ? nonMobileWidth : 'auto'};
  }
`;
export const StyledInputWrapper = styled.div`
  position: relative;
  padding-bottom: 20px;
`;
export const StyledLeftIconContainer = styled.div`
  position: absolute;
  display: block;
  left: 20px;
  bottom: calc(50% - 7px);
  transform: translateY(-50%);
`;
export const StyledRightIconContainer = styled.div`
  position: absolute;
  display: block;
  right: 20px;
  bottom: calc(50% - 7px);
  transform: translateY(-50%);
`;

export const StyledLabel = styled.label<
  React.LabelHTMLAttributes<HTMLLabelElement>
>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: 'Moderat';
  color: ${({ theme }) => theme.colors.textColors.secondaryDark};
  margin-bottom: 10px;
`;
export const StyledTagContainer = styled.span`
  color: ${({ theme }) => theme.colors.textColors.tertiaryDark};
  display: flex;
  align-items: center;
`;
export const StyledInput = styled.input<InputPropsType>`
  width: 100%;
  display: block;
  padding: 14px;
  padding-left: ${({ LeftIcon }) => (LeftIcon ? '52px' : '20px')};
  padding-right: ${({ RightIcon }) => (RightIcon ? '52px' : '20px')};
  border: 1px solid ${({ theme }) => theme.colors.neutralColors.lighterGray};
  border-radius: 4px;
  font-family: 'Moderat';
  color: ${({ theme }) => theme.colors.textColors.primaryDark};
  font-size: 1.8rem;
  line-height: 2.8rem;
  &:focus,
  &:focus-visible {
    border: 1px solid ${({ theme }) => theme.colors.neutralColors.charcoal};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.neutralColors.charcoal};
    outline: none;
  }
  ${({ theme, error, errorText }) =>
    (error || errorText) &&
    `border: 1px solid ${theme.colors.systemSupportColors.error};`}
  &:disabled {
    background: ${({ theme }) => theme.colors.neutralColors.concrete};
    color: ${({ theme }) => theme.colors.textColors.tertiaryDark};
  }
`;
export const StyledInfoSVG = styled(InfoIcon)`
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.textColors.tertiaryDark};
`;

export const StyledErrorText = styled.div`
  position: absolute;
  font-family: 'Moderat';
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.systemSupportColors.error};
  bottom: 0;
`;
