import { SVGProps } from 'react';
import { useTheme } from 'styled-components';
import {
  StyledContainer,
  StyledErrorText,
  StyledInfoSVG,
  StyledInput,
  StyledInputWrapper,
  StyledLabel,
  StyledLeftIconContainer,
  StyledRightIconContainer,
  StyledTagContainer,
} from './styles';

export type InputPropsType = {
  nonMobileWidth?: string;
  mobileWidth?: string;
  LeftIcon?: React.FC<SVGProps<SVGSVGElement>>;
  RightIcon?: React.FC<SVGProps<SVGSVGElement>>;
  error?: boolean;
  errorText?: string;
  label?: string;
  hasOptionalTag?: boolean;
  hasTooltipIcon?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Input: React.FC<InputPropsType> = ({
  nonMobileWidth,
  mobileWidth,
  LeftIcon,
  RightIcon,
  disabled,
  label,
  id,
  hasOptionalTag,
  hasTooltipIcon,
  error,
  errorText,
  ...rest
}) => {
  const { colors } = useTheme();
  return (
    <StyledContainer nonMobileWidth={nonMobileWidth} mobileWidth={mobileWidth}>
      <StyledLabel htmlFor={id}>
        <span>{label}</span>
        <StyledTagContainer>
          {hasOptionalTag && 'Optional'}
          {/* TODO: if using tooltip input text into tooltip icon? */}
          {hasTooltipIcon && <StyledInfoSVG aria-label="Tooltip" />}
        </StyledTagContainer>
      </StyledLabel>
      <StyledInputWrapper>
        {LeftIcon && (
          <StyledLeftIconContainer>
            <LeftIcon
              color={
                disabled
                  ? colors.textColors.tertiaryDark
                  : colors.textColors.primaryDark
              }
            />
          </StyledLeftIconContainer>
        )}
        <StyledInput
          id={id}
          LeftIcon={LeftIcon}
          RightIcon={RightIcon}
          disabled={disabled}
          error={error}
          errorText={errorText}
          aria-describedby={`${id}-error`}
          {...rest}
        />
        {RightIcon && (
          <StyledRightIconContainer>
            <RightIcon
              color={
                disabled
                  ? colors.textColors.tertiaryDark
                  : colors.textColors.primaryDark
              }
            />
          </StyledRightIconContainer>
        )}
        <StyledErrorText id={`${id}-error`} aria-live="polite">
          {errorText}
        </StyledErrorText>
      </StyledInputWrapper>
    </StyledContainer>
  );
};

export default Input;
