import styled from 'styled-components';
import { getStyles } from '../styles';
import { BaseType } from '../types';

type ButtonType = BaseType &
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof BaseType>;

const StyledButton = styled.button<ButtonType>`
  ${({
    theme,
    colorBase = 'charcoal',
    variant = 'solid',
    disabled,
    nonMobileWidth,
  }) => getStyles(theme, colorBase, variant, disabled, nonMobileWidth)}
`;

const Button: React.FC<ButtonType> = ({ children, ...rest }) => (
  <StyledButton {...rest}>{children}</StyledButton>
);

export default Button;
