import styled from 'styled-components';

export const TooltipContainer = styled.div`
  border-radius: 4px;
  padding: 16px;
  text-align: center;
  background: ${({ theme }) => theme.colors.neutralColors.charcoal};
  color: ${({ theme }) => theme.colors.neutralColors.white};
  margin: 0;
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: 'Moderat';
  .arrow {
    position: absolute;
    &:after {
      content: ' ';
      background: ${({ theme }) => theme.colors.neutralColors.charcoal};
      box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
      position: absolute;
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
    }
  }

  &[data-popper-placement^='top'] > .arrow {
    bottom: 5px;
    left: -5px !important;
    :after {
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }
  }
  &[data-popper-placement^='bottom'] > .arrow {
    top: 5px;
    left: -5px !important;
    :after {
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
      bottom: 0;
    }
  }
`;

export const StyledWrapper = styled.div`
  width: fit-content;
`;
