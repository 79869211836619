export const brandColors = {
  birchLighter: "#FFFDFA",
  birchLight: "#FDF7ED",
  birch: "#FAF0DF",
  birchDark: "#F1E2C8",
  birchDarker: "#DDC9A6",
  coralLighter: "#FEBEAE",
  coralLight: "#FE9175",
  coral: "#FE7E62",
  coralDark: "#9D2F15",
  coralDarker: "#591203",
  glacierLighter: "#B6E2DC",
  glacierLight: "#98D6CE",
  glacier: "#86CCC3",
  glacierDark: "#107572",
  glacierDarker: "#1A322F",
};

export type BrandColorsType = keyof typeof brandColors;

export const neutralColors = {
  white: "#FFFFFF",
  concrete: "#F7F7F7",
  charcoal: "#160E08",
  lightestGray: "#EBEBEA",
  lighterGray: "#C5C4C3",
  lightGray: "#999795",
  gray: "#767370",
  darkGray: "#5D5A57",
  darkerGray: "#29231F",
  jetBlack: "#070503",
};

export type NeutralColorsType = keyof typeof neutralColors;

export const textColors = {
  primaryDark: "#160E08",
  secondaryDark: "rgba(22, 14, 8, 0.75)",
  tertiaryDark: "rgba(22, 14, 8, 0.58)",
  primaryLight: "#FFFFFF",
  secondaryLight: "rgba(255, 255, 255, 0.75)",
  tertiaryLight: "rgba(255, 255, 255, 0.6)",
};

export type TextColorsType = keyof typeof textColors;

export const systemSupportColors = {
  error: "#B20000",
};

export type SystemSupportColorsType = keyof typeof systemSupportColors;
