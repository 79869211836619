import React, { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { TooltipContainer, StyledWrapper } from './styles';

type TooltipProps = {
  tooltip: React.ReactNode;
};

const Tooltip: React.FC<TooltipProps> = ({ tooltip, children }) => {
  const [showTooltipHover, setShowTooltipHover] = useState(false);
  const [showTooltipClick, setShowTooltipClick] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const popperRef = useRef(null);
  // the ref for the arrow must be a callback ref
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.addEventListener('mouseenter', () =>
        setShowTooltipHover(true),
      );
      wrapperRef.current.addEventListener('mouseleave', () =>
        setShowTooltipHover(false),
      );
    }
    return () => {
      if (wrapperRef.current) {
        wrapperRef.current.removeEventListener('mouseenter', () =>
          setShowTooltipHover(true),
        );
        wrapperRef.current.removeEventListener('mouseleave', () =>
          setShowTooltipHover(false),
        );
      }
    };
  }, []);

  const { styles, attributes } = usePopper(
    wrapperRef.current,
    popperRef.current,
    {
      placement: 'top',
      modifiers: [
        {
          name: 'arrow',
          options: {
            element: arrowRef,
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ],
    },
  );
  return (
    <div>
      <StyledWrapper
        ref={wrapperRef}
        onClick={() => setShowTooltipClick(!showTooltipClick)}
      >
        {children}
      </StyledWrapper>
      {(showTooltipHover || showTooltipClick) && (
        <TooltipContainer
          onClick={() => setShowTooltipClick(false)}
          ref={popperRef}
          style={styles.popper}
          {...attributes.popper}
        >
          <div ref={setArrowRef} style={styles.arrow} className="arrow" />
          {tooltip}
        </TooltipContainer>
      )}
    </div>
  );
};

export default Tooltip;
