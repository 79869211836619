import styled from 'styled-components';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledTop = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.neutralColors.charcoal};
`;
export const StyledHeader = styled.div`
  position: relative;
  height: 68px;
  display: none;
  ${({ theme }) => theme.breakPoints.desktop} {
    display: block;
  }
  &::after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const StyledAvatar = styled.img`
  width: 88px;
  margin: 0 auto;
  padding-top: 64px;
  padding-bottom: 16px;
  display: none;
  ${({ theme }) => theme.breakPoints.desktop} {
    display: block;
  }
`;

export const StyledHandWritting = styled.p`
  font-family: 'Caveat';
  font-size: 2rem;
  line-height: 3.2rem;
  margin-bottom: 2px;
  color: ${({ theme }) => theme.colors.brandColors.coralLight};
  margin: 0 auto;
  text-align: center;
  padding-top: 64px;
  ${({ theme }) => theme.breakPoints.desktop} {
    padding-top: 0;
  }
`;

export const StyledHeading = styled.h1`
  color: ${({ theme }) => theme.colors.textColors.primaryLight};
  font-size: 3.6rem;
  line-height: 4rem;
  text-align: center;
  font-family: 'Ivar Fine Light';
  font-weight: 400;
  margin: 0 auto;
  margin-bottom: 16px;
`;

export const StyledCloseButton = styled.button`
  position: absolute;
  right: 24px;
  top: 24px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1;
  svg {
    color: white;
    font-size: 2rem;
  }
`;

export const StyledParagraph = styled.p`
  font-size: 1.6rem;
  line-height: 2.4rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.textColors.secondaryLight};
  width: 300px;
  margin: 0 auto;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 648px;
  margin: 0 auto;
  margin-top: 48px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralColors.darkGray};
  padding-bottom: 68px;
  ${({ theme }) => theme.breakPoints.desktop} {
    padding-bottom: 0;
  }
`;

export const StyledButton = styled.a`
  background: ${({ theme }) => theme.colors.neutralColors.concrete};
  width: 80px;
  height: 80px;
  border: none;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin: 16px;
  svg {
    font-size: 2.5rem;
  }
  span {
    position: absolute;
    bottom: -32px;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.neutralColors.lightestGray};
  }
  ${({ theme }) => theme.breakPoints.desktop} {
    display: none;
  }
`;

export const StyledWarning = styled.p`
  color: ${({ theme }) => theme.colors.brandColors.coralLight};
  font-size: 1.6rem;
  text-align: center;
  padding-top: 32px;
  margin: 0;
  padding-bottom: 44px;
`;

export const StyledBottom = styled.div`
  padding: 22px;
  padding-top: 32px;
  max-width: 648px;
  margin: 0 auto;
`;

export const StyledH2 = styled.h2`
  font-size: 2rem;
  line-height: 2.4rem;
  margin: 0;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.textColors.primaryDark};
`;

export const StyledAnchor = styled.a`
  font-size: 1.6rem;
  font-family: 'Moderat Bold';
  color: ${({ theme }) => theme.colors.textColors.primaryDark};
  text-decoration: none;
`;

export const StyledDescription = styled.p`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.textColors.secondaryDark};
`;

export const StyledDivider = styled.div`
  height: 1.5px;
  width: 100%;
  background: ${({ theme }) => theme.colors.neutralColors.charcoal};
  opacity: 0.1;
  margin: 24px 0;
`;
