import styled from 'styled-components';
import { Button } from '../../components/Buttons';

export const StyledForm = styled.form`
  width: 100%;
  margin: 0 auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 68px);
  max-height: 840px;
  max-width: 480px;
`;
export const StyledGreeting = styled.h2`
  font-size: 3.6rem;
  font-family: 'Ivar Fine Light';
  font-weight: 400;
  text-align: center;
  margin-top: 48px;
  margin-bottom: 16px;
`;

export const StyledParagraph = styled.p`
  font-size: 1.6rem;
  text-align: center;
  margin: 0 20px 74px 20px;
  color: ${({ theme }) => theme.colors.textColors.secondaryDark};
`;

export const StyledOTPContainer = styled.div`
  max-width: 340px;
  margin: 0 auto;
  button {
    padding: 0;
  }
`;

export const StyledForgottenButtonContainer = styled.div`
  margin-bottom: 40px;
`;

export const StyledButtonContainer = styled.div`
  ${({ theme }) => theme.breakPoints.tablet} {
    display: flex;
  }
`;
export const StyledHelpButton = styled(Button)`
  margin-top: 8px;
  margin-left: 0;
  ${({ theme }) => theme.breakPoints.tablet} {
    margin-top: 0;
    margin-left: 8px;
  }
`;
