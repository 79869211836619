import {
  StyledHeader,
  StyledCloseButton,
  StyledTop,
  StyledHeading,
  StyledParagraph,
  ButtonWrapper,
  StyledButton,
  StyledWarning,
  StyledBottom,
  StyledH2,
  StyledAnchor,
  StyledDescription,
  StyledDivider,
  StyledHandWritting,
  StyledWrapper,
  StyledAvatar,
} from './styles';
import Avatar from '../../assets/images/Avatar Group.png';
import { MailIcon, PhoneIcon, XIcon } from '../../assets/SVG';

type HelpProps = {
  closeHelp: React.Dispatch<React.SetStateAction<boolean>>;
};

const Help: React.FC<HelpProps> = ({ closeHelp }) => {
  return (
    <StyledWrapper>
      <StyledCloseButton onClick={() => closeHelp(false)}>
        <XIcon />
      </StyledCloseButton>
      <StyledTop>
        <StyledHeader />
        <StyledAvatar src={Avatar} alt="Avatar image" />
        <StyledHandWritting>Questions or Concerns?</StyledHandWritting>
        <StyledHeading>Ask your Care Team</StyledHeading>
        <StyledParagraph>
          Any questions about your Care?
          <br /> Your Commons team is here to help.
        </StyledParagraph>
        <ButtonWrapper>
          <StyledButton href="mailto:hello@commonsclinic.com">
            <MailIcon />
            <span>Email</span>
          </StyledButton>
          {/* TODO: Uncomment when messaging is rolled out */}
          {/* <StyledButton href="">
            <MessageIcon />
            <span>Message</span>
          </StyledButton> */}
          <StyledButton href="tel:1-310-595-1030">
            <PhoneIcon />
            <span>Call</span>
          </StyledButton>
        </ButtonWrapper>
        <StyledWarning>If there is an emergency, please call 911</StyledWarning>
      </StyledTop>
      <StyledBottom>
        <StyledH2>Email</StyledH2>
        <StyledAnchor href="mailto:hello@commonsclinic.com">
          hello@commonsclinic.com
        </StyledAnchor>
        <StyledDescription>
          Monday - Friday 9:00am - 5:00pm PT
        </StyledDescription>
        <StyledDivider />
        <StyledH2>Call</StyledH2>
        <StyledAnchor href="tel:1-310-595-1030">1-310-595-1030</StyledAnchor>
        <StyledDescription>
          Monday - Friday 9:00am - 5:00pm PT
        </StyledDescription>
      </StyledBottom>
    </StyledWrapper>
  );
};

export default Help;
