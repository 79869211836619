import { SVGProps } from 'react';
import styled from 'styled-components';

const SVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.976 12 14 14V3.53a.496.496 0 0 0-.179-.375.662.662 0 0 0-.432-.155H2.61a.662.662 0 0 0-.432.155A.496.496 0 0 0 2 3.53v7.942c0 .14.064.275.179.374.115.1.27.155.432.155h7.365Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const MessageIcon = styled(SVG)`
  font-size: ${props => (props.fontSize ? props.fontSize : '1.6rem')};
  color: ${({ color, theme }) =>
    color ? color : theme.colors.neutralColors.charcoal};
  vertical-align: middle;
  shape-rendering: inherit;
  transform: translate3d(0, 0, 0);
`;
export default MessageIcon;
