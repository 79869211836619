import styled from 'styled-components';
import { InfoIcon } from '../../assets/SVG';

const StyledContainer = styled.div`
  padding: 16px;
  display: flex;
  background: ${({ theme }) => theme.colors.neutralColors.concrete};
  max-width: 480px;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 8px;
`;

const StyledInfo = styled(InfoIcon)`
  width: 18px;
  height: 18px;
  display: block;
`;

const StyledText = styled.p`
  margin: 0;
  margin-left: 11px;
  font-family: 'Moderat';
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.textColors.secondaryDark};
`;
const Emergency = () => {
  return (
    <StyledContainer>
      <div>
        <StyledInfo />
      </div>
      <StyledText>
        If you are experiencing a medical emergency please call 911
      </StyledText>
    </StyledContainer>
  );
};

export default Emergency;
