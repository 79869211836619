import { SVGProps } from 'react';
import styled from 'styled-components';

const SVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4Z"
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path
      d="M7.293 9.293 2.5 4.707C1.87 4.077 2.316 3 3.207 3h9.586c.89 0 1.337 1.077.707 1.707L8.707 9.293a1 1 0 0 1-1.414 0Z"
      stroke="currentColor"
      strokeWidth={1.5}
    />
  </svg>
);

const MailIcon = styled(SVG)`
  font-size: ${props => (props.fontSize ? props.fontSize : '1.6rem')};
  color: ${({ color, theme }) =>
    color ? color : theme.colors.neutralColors.charcoal};
  vertical-align: middle;
  shape-rendering: inherit;
  transform: translate3d(0, 0, 0);
`;
export default MailIcon;
