import React, { useEffect, useRef, useState } from 'react';
import {
  StyledAutocompleteInput,
  StyledError,
  StyledFlexContainer,
  StyledWrapper,
  StyledInfoIcon,
  StyledInput,
  StyledLabel,
} from './styles';
import { initialValue, OTPCodeType } from '../../containers/Login';
import Tooltip from '../Tooltip';

type OTPInputProps = {
  errorText?: string;
  value: OTPCodeType;
  setValue: React.Dispatch<React.SetStateAction<OTPCodeType>>;
  setErrorText: React.Dispatch<React.SetStateAction<string>>;
  disabled: boolean;
  handleReset: () => void;
};

const isOTPCodeType = (arg: string[]): arg is OTPCodeType => {
  return (
    Array.isArray(arg) &&
    arg.every((item: string) => typeof item === 'string') &&
    arg.length === 5
  );
};

const OTPInput: React.FC<OTPInputProps> = ({
  value,
  setValue,
  errorText,
  disabled,
  setErrorText,
  handleReset,
}) => {
  const [autoComplete, setAutoComplete] = useState<string>('');
  const [activeElement, setActiveElement] = useState<number | null>(0);

  const refs = useRef<Array<HTMLInputElement | null>>([]);
  // Initialize refs
  useEffect(() => {
    refs.current = refs.current.slice(0, initialValue.length);
    if (refs.current[0]) {
      refs.current[0].focus();
    }
  }, []);
  // Select active element when active element value changes
  useEffect(() => {
    if (activeElement !== null && refs.current[activeElement]) {
      refs.current[activeElement]?.select();
    }
  }, [activeElement]);
  useEffect(() => {
    const newValue = autoComplete.split('');
    if (isOTPCodeType(newValue)) {
      setValue(newValue);
    }
  }, [autoComplete]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    //Fix for ios chrome autocomplete
    if (e.target.value.length === 5) {
      const chromeIOSAutocomplete = e.target.value.split('');
      if (isOTPCodeType(chromeIOSAutocomplete)) {
        setValue(chromeIOSAutocomplete);
        setActiveElement(null);
        return;
      }
    }

    const newValue = [...value] as OTPCodeType;
    newValue.splice(i, 1, e.target.value);
    setErrorText('');
    setValue(newValue);
    focusNextElement();
  };

  const handleClick = (i: number) => {
    refs.current[i]?.select();
    setActiveElement(i);
    setErrorText('');
  };

  const focusNextElement = () => {
    setActiveElement(activeElement ? activeElement + 1 : 1);
  };

  const focusPreviousElement = () => {
    setActiveElement(activeElement ? activeElement - 1 : 0);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text/plain').split('');
    if (isOTPCodeType(pasteData)) {
      setValue(pasteData);
    } else {
      setErrorText(
        'Paste does not match the code structure, please copy the entire code',
      );
    }
  };

  const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Backspace':
        if (activeElement !== null) {
          e.preventDefault();
          focusPreviousElement();
        }
        break;
      case 'ArrowLeft':
        if (activeElement !== null) {
          focusPreviousElement();
        }
        break;
      case 'ArrowRight':
        if (activeElement !== null && activeElement < 4) focusNextElement();
        break;
      case 'Spacebar': {
        e.preventDefault();
      }
    }
  };

  return (
    <StyledWrapper>
      <StyledAutocompleteInput
        tabIndex={-1}
        aria-hidden
        autoComplete="one-time-code"
        value={autoComplete}
        onChange={e => setAutoComplete(e.target.value)}
        onClick={handleReset}
      />
      <StyledFlexContainer>
        <StyledLabel>Code</StyledLabel>
        <Tooltip tooltip="If you havent received your code, your credentials may not be valid.">
          <StyledInfoIcon />
        </Tooltip>
      </StyledFlexContainer>
      <StyledFlexContainer>
        {initialValue.map((_, i) => (
          <StyledInput
            ref={el => (refs.current[i] = el)}
            tabIndex={i + 1}
            type="text"
            aria-label={`One time password input for the ${i + 1} digit`}
            inputMode="numeric"
            pattern="[0-9]*"
            //Fix for ios chrome autocomplete
            maxLength={i === 0 ? 5 : 1}
            onFocus={() => setActiveElement(i)}
            onPaste={handlePaste}
            onChange={e => handleChange(e, i)}
            onClick={() => handleClick(i)}
            onInput={focusNextElement}
            onKeyDown={handleKeydown}
            index={i}
            key={i}
            disabled={disabled}
            value={value[i]}
            error={errorText ? true : false}
            aria-invalid={errorText ? true : false}
          />
        ))}
      </StyledFlexContainer>
      {errorText && <StyledError>{errorText}</StyledError>}
    </StyledWrapper>
  );
};

export default OTPInput;
