import styled from 'styled-components';
import { InfoIcon } from '../../assets/SVG';

type StyledInputType = {
  error?: boolean;
  index: number;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const StyledWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 48px;
`;

export const StyledAutocompleteInput = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledLabel = styled.h3`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.textColors.secondaryDark};
  font-family: 'Moderat';
  margin: 0;
  margin-bottom: 8px;
`;

export const StyledFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledInfoIcon = styled(InfoIcon)`
  height: 16px;
  width: 16px;
  color: ${({ theme }) => theme.colors.textColors.tertiaryDark};
`;

export const StyledInput = styled.input<StyledInputType>`
  height: 55px;
  width: 55px;
  font-size: 1.8rem;
  line-height: 2.8rem;
  color: ${({ theme }) => theme.colors.textColors.primaryDark};
  display: flex;
  justify-content: center;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.colors.neutralColors.lighterGray};
  border-radius: 4px;
  font-family: 'Moderat';
  &:focus,
  &:focus-visible {
    border: 1px solid ${({ theme }) => theme.colors.neutralColors.charcoal};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.neutralColors.charcoal};
    outline: none;
  }
  ${({ theme, error }) =>
    error && `border: 1px solid ${theme.colors.systemSupportColors.error};`}
  &:disabled {
    background: ${({ theme }) => theme.colors.neutralColors.concrete};
    color: ${({ theme }) => theme.colors.textColors.tertiaryDark};
  }
`;
export const StyledError = styled.strong`
  font-family: 'Moderat';
  color: ${({ theme }) => theme.colors.systemSupportColors.error};
  font-size: 1.4rem;
  line-height: 2rem;
  display: block;
  width: 250px;
  position: absolute;
  top: 85px;
  left: 0;
`;
