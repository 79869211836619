import React from 'react';
import ReactDOM from 'react-dom';
import Login from './containers/Login';

import Amplify from '@aws-amplify/core';
import config from './config';

import { ThemeProvider } from 'styled-components';
import { CommonsTheme } from './components/Theme';
import './index.css';

Amplify.configure({
  Auth: {
    region: config.aws.region,
    userPoolId: config.auth.userPoolId,
    userPoolWebClientId: config.auth.userPoolWebClientId,
    cookieStorage: {
      domain: config.auth.cookieDomain, // Use a single space " " for host-only cookies
      expires: 2592000, // null means session cookies
      path: '/',
      secure: config.auth.cookieSecure, // for developing on localhost over http: set to false
      sameSite: 'lax',
    },
    oauth: {
      domain: config.auth.userPoolDomain,
      scope: config.auth.scope,
      redirectSignIn: `https://${window.location.hostname}/parseauth`,
      redirectSignOut: `https://${window.location.hostname}/`,
      responseType: 'code',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={CommonsTheme}>
      <Login />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
