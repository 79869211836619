import styled, { DefaultTheme } from 'styled-components';
import { ButtonColorBaseType } from '../types';

type ButtonSize = 'large' | 'default' | 'small';
type InlineButtonType = {
  colorBase?: ButtonColorBaseType;
  disabled?: boolean;
  size?: ButtonSize;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const getSize = (size: ButtonSize) => {
  switch (size) {
    case 'small':
      return `
                font-size: 1.4rem;
            `;
    case 'default':
      return `
                font-size: 1.6rem;
            `;
    case 'large':
      return `
                font-size: 1.8rem;
            `;
  }
};

const getColors = (
  theme: DefaultTheme,
  colorBase: ButtonColorBaseType,
  disabled: boolean,
) => {
  switch (colorBase) {
    case 'charcoal':
      if (disabled) {
        return `
                    color: ${theme.colors.textColors.tertiaryDark};
                    pointer-events: none;
                `;
      }
      return `
                color: ${theme.colors.textColors.secondaryDark}; 
                    &:hover, &:focus  {
                        color: ${theme.colors.textColors.primaryDark};
                        text-decoration: underline 2px solid ${theme.colors.brandColors.coral};
                } 
            `;
    case 'white':
      if (disabled) {
        return `
                    color: ${theme.colors.textColors.tertiaryLight}; 
                    pointer-events: none;
                `;
      }
      return `color: ${theme.colors.textColors.primaryLight}; 
                &:hover, &:focus  {
                    text-decoration: underline 2px solid ${theme.colors.brandColors.coral};
                }
            `;
  }
};

const StyledInlineButton = styled.button<InlineButtonType>`
  font-family: 'Moderat';
  padding: 0;
  text-decoration: none;
  transition: all 0.1s;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  ${({ size = 'default' }) => getSize(size)}
  ${({ theme, colorBase = 'charcoal', disabled = false }) =>
    getColors(theme, colorBase, disabled)}
`;

const InlineButton: React.FC<InlineButtonType> = ({ children, ...rest }) => {
  return <StyledInlineButton {...rest}>{children}</StyledInlineButton>;
};

export default InlineButton;
